<template>
    <div class="events-collection-page">
        <div class="hero">
            <HeroBasic
                :heading="title"
                accentColor="grey"
                :showTopographic="true"
                :hasHeaderOffset="true"
                :richText="dek"
            >
                <template #headerDetails>
                    <div class="header-details">
                        <AvatarSet v-if="contributors?.length" :people="contributors" class="credits"></AvatarSet>
                        <div class="controls">
                            <div class="social-share controls-item">
                                <ShareButton />
                                <CopyLinkButton />
                            </div>
                        </div>
                    </div>
                </template>
            </HeroBasic>
        </div>

        <SubnavBlock :navigationLinks="parent?.value?.subNav?.navigationLinks">
            <template #right-content>
                <SubscribeBlock :buttonLabel="$t('sign up for events')" mode="button" newsletterFormName="BasicForm" />
            </template>
        </SubnavBlock>

        <StreamTierFeed :streamTiered="streamTiered" />

        <client-only>
            <search
                :page-language="pageLanguage"
                :index-name="`content_${pageLanguage}`"
                :filter="`syndications.eventCollectionIds = ${id}`"
                :enabled-filters="{ topics: false, regions: false, years: false, experts: false }"
                :has-filters="false"
            >
                <template #results="{ results }">
                    <div class="desktop-results">
                        <ContentCardBlock
                            v-for="result in results"
                            :key="result.id"
                            :related="transformContentSearchIndexToContentCardBlockProps(result)"
                            orientation="wide"
                            size="100"
                            :hideDescription="true"
                            :isProportionate="true"
                            class="result"
                            new-search
                        />
                    </div>
                    <div class="mobile-results">
                        <ContentCardBlock
                            v-for="result in results"
                            :key="result.id"
                            :related="transformContentSearchIndexToContentCardBlockProps(result)"
                            orientation="wide"
                            size="50"
                            :hideDescription="true"
                            :isProportionate="true"
                            class="result"
                            new-search
                        />
                    </div>
                </template>
            </search>
        </client-only>
    </div>
</template>

<script setup>
import transformContentSearchIndexToContentCardBlockProps from '@/utils/search/transformContentSearchIndexToContentCardBlock';
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    id: {
        type: String,
    },
    dek: {
        type: Object,
    },
    contentType: {
        type: String,
        default: '',
    },
    contributors: {
        type: Array,
    },
    streamTiered: {
        type: Object,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
    parent: {
        type: Object,
    },
});

const { pageLanguage } = usePageDataStore();
const headerAttributesStore = useHeaderAttributesStore();

const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Event Collection',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.header-details {
    display: flex;
    max-width: 77rem;
    gap: var(--col-gutter);
    flex-wrap: wrap;
    margin-top: vertical-space(1);
}

.controls {
    margin-top: vertical-space(1);
    display: flex;
    gap: 0.8rem;
    align-items: center;
}

.social-share {
    margin-inline-start: 0.8rem;
    display: flex;
    gap: 0.4rem;
    align-items: center;
}

.result {
    padding-top: vertical-space(2);
    padding-bottom: vertical-space(2);
    border-bottom: 1px solid palette(bg-gray-dark);
}
.desktop-results {
    display: none;
    @include media-query(phone-mw) {
        display: block;
    }
}
.mobile-results {
    @include media-query(phone-mw) {
        display: none;
    }
}
</style>
